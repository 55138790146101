<template>
  <div>
    <div>
      <h1 class="white--text text-center ma-5">FAQs</h1>
    </div>
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col lg="6" md="8" sm="10" xs="12">
          <v-card v-for="(faq, i) in faqs" :key="i" :class="i > 0 ? 'mt-5' : ''">
            <v-card-title class="eur-card-title"> Q: {{ faq.question }} </v-card-title>
            <v-card-text
              ><p class="mt-5" v-html="'A: ' + faq.answer"/>
              <v-img contain v-if="faq.image" :src="faq.image" :alt="faq.imageAlt" :width="faq.imageWidth"></v-img
            ></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CeurFaq",
  metaInfo() {
    return {
      title: this.$i18n.translate("End User Rebate FAQ")
    };
  },
  data() {
    return {
      faqs: []
    };
  },
  computed: {
    ...mapGetters(["selectedLocale", "selectedProgram"]),
    faqsEn() {
      return [
        {
          question: "How do I submit a claim to earn a rebate?",
          answer: `Visit our Sony professional products end user rebate portal: <a href='http://www.sony.com/prorebates' target='_blank'>www.sony.com/prorebates</a>. Locate the promotion for the eligible Sony professional product purchased and select 'Claim'. For assistance, you may email our rebate support team via at <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a> or call our rebate support team at ${this.selectedProgram.programPhone}.`
        },
        {
          question: "When asked to provide the product UPC what does that mean?",
          answer:
            "Product Bar Codes, or Serial Numbers can be found on the outside of the original product packaging. Your sales invoice may also contain this detail. The supporting documentation that is required during the claim submission must contain the product and serial number information in order to validate the sale.",
          image: require("@/assets/sample-barcodes.png"),
          imageAlt: "Sample Barcodes",
          imageWidth: "600"
        },
        {
          question:
            "I bought more than one of the same product that a rebate was offered on. Can I submit for more than one rebate?",
          answer:
            "Many rebates have a limit on the number of claims that can be submitted on a single promotion. To find out the specific terms and conditions for the promotion you are claiming under, please review the promotion details provided online. Visit <a href='http://www.sony.com/prorebates' target='_blank'>www.sony.com/prorebates</a> for more information."
        },
        {
          question:
            "I purchased the product that I am submitting a rebate request for online and did not receive an invoice. Can I still receive a rebate?",
          answer:
            "Qualifying purchases made online should have been accompanied by a shipment confirmation email. For these purchases, a shipment confirmation email may be used a proof of purchase/invoice."
        },
        {
          question: "How do I check the status of my rebate request?",
          answer: `Checking the status of your rebate request is easy. You can check the status of your rebates by simply clicking 'TRACK MY REBATE' and enter your email address and zip code or the claim number. For assistance, you may email our rebate support team at <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a> or call our rebate support team: ${this.selectedProgram.programPhone}.`
        },
        {
          question:
            "I checked the status of my rebate request and it states that my rebate request could not be processed. What do I need to do to receive my rebate?",
          answer: `If your rebate request is invalid due to missing information such as a copy of the product UPC or Invoice, provided you are within the promotion claim deadline, please submit the required invoice and UPC image to our rebate support team at <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a>. Please refer to the promotion details for the claim deadline date.`
        },
        {
          question: "How long does it take to receive my rebate?",
          answer:
            "Please allow up to 8 weeks from when you send in your submission to receive your rebate. It can arrive sooner, but we ask that you wait the full 8 weeks to allow sufficient processing time."
        },
        {
          question: "It's been longer than 8 weeks and I still have not received my rebate. What should I do?",
          answer: `First, visit <a href='http://www.sony.com/prorebates' target='_blank'>www.sony.com/prorebates</a> and check the status of your claim to ensure your claim has not be rejected. If your claim has been approved and the eight weeks have passed, please contact our rebate support team via email at <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a> or by phone ${this.selectedProgram.programPhone} for assistance.`
        },
        {
          question: "Why does Sony take so long to fulfill my rebate?",
          answer:
            "Sony uses a third-party fulfillment company that specialize in processing rebates and can handle them most efficiently. We need to allow the rebate processing company enough time audit your claim and for payment processing. "
        },
        {
          question: "I found out that my rebate submission was rejected. What do I need to do to receive my rebate?",
          answer: `If your rebate is rejected due to missing information such as the UPC, serial number or other proof of purchase requirements, you may resubmit the missing information to the rebate support team via email at <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a>. You should receive an email stating that your rebate was rejected and the rejection reason. We encourage you to resubmit your claim with the correct information within 30 days of the date the email. If your rebate is rejected due to reasons other than missing information, please review the rebate requirements on the coupon or website. It is possible that the requirements were not met.`
        },
        {
          question:
            "I forgot to send in for my rebate and now it has expired. If I send it in late, will I be able to get my rebate?",
          answer:
            "Promotions such as rebates are carefully planned for very specific time frames. If the claim deadline has passed for your rebate, unfortunately you will not qualify. Please be sure to send in for your rebate right away in the future."
        },
        {
          question: "How does Sony payout 'cash' rebates?",
          answer:
            "All 'cash' rebates will be paid out to US customers in the form of a Single Load Debit Card and to Canadian customers in the form of an ACH Bank Transfer. For Canadian customers, once your claim is approved you will be contacted directly from the rebate support team requesting the necessary banking information for your payout. "
        },
        {
          question: "How do I activate my Card?  ",
          answer:
            "Call 1-833-848-5768 to activate your Card. You will need the Access Code and the security code on the back of your Card. During this call, you will receive a Personal Identification Number (PIN), you may keep the PIN assigned to you or change your PIN at any time by calling the number on the back of your Card."
        },
        {
          question: "How can I check the balance and transaction detail on my Card?",
          answer:
            "Balance and transaction details are always available to you, free of charge, online at <a href='http://www.card-support.com' target='_blank'>www.card-support.com</a> or by phone at 1-833-848-5768. "
        },
        {
          question: "How does the Card work?",
          answer:
            "Once your Card is activated, you can use your Card to make purchases at merchants by presenting it in person, online or by phone. Approved transactions will be deducted from your Card balance. Swipe and sign or use your PIN."
        },
        {
          question: "Do my funds ever expire?",
          answer:
            "Available funds on your Card do not expire. Please note that your Card has a 'Valid Thru' date on the front of the Card. You may not use the Card after the 'Valid Thru' date. The funds on the card will not be available to you after this date. It is important that you use your funds prior to this date."
        }
      ];
    },
    faqsFr() {
      return [
        {
          question: "Comment puis-je soumettre une réclamation pour gagner une remise?",
          answer: `Visitez notre portail de remise des utilisateurs finaux de Sony Professional Products:<a href='http://www.sony.com/prorebates' target='_blank'>www.sony.com/prorebates</a>. Localisez la promotion du produit professionnel Sony éligible acheté et sélectionnez «Réclamation».Pour obtenir de l'aide, vous pouvez envoyer par courrier électronique à notre équipe de support de remise via à <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a> Ou appelez notre équipe de support de remise à ${this.selectedProgram.programPhone}.`
        },
        {
          question: "Si on me demande de télécharger une copie du produit ''UPC'', Qu'est-ce que ça veut dire?",
          answer: `Cela signifie que vous devez télécharger le code à barres du produit, généralement trouvé à l'extérieur de l'emballage de produit d'origine.Voir l'exemple d'image ci-dessous.Si vous n'êtes pas sûr, demandez à quelqu'un au point d'achat ou d'envoyer par courrier électronique à notre équipe de support de remise à <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a>.`,
          image: require("@/assets/sample-barcodes.png"),
          imageAlt: "Exemple de code à barres",
          imageWidth: "600"
        },
        {
          question:
            "J'ai acheté plus d'un des mêmes produits qu'un remboursement a été offert.Puis-je soumettre pour plus d'un rabais?",
          answer:
            "De nombreux remises ont une limite sur le nombre de réclamations pouvant être soumises à une seule promotion.Pour connaître les termes et conditions spécifiques de la promotion que vous réclamez sous, veuillez consulter les détails de la promotion fournis en ligne.Visite <a href='http://www.sony.com/prorebates' target='_blank'>www.sony.com/prorebates</a> pour plus d'informations."
        },
        {
          question:
            "J'ai acheté le produit que je soumets à une demande de remise en ligne et n'a pas reçu de facture.Puis-je toujours recevoir une remise?",
          answer:
            "Les achats de qualification effectués en ligne auraient dû être accompagnés d'un courrier électronique de confirmation d'envoi.Pour ces achats, un email de confirmation d'expédition peut être utilisé une preuve d'achat / facture."
        },
        {
          question: "Comment vérifier le statut de ma demande de remise? ",
          answer: `Vérification de l'état de votre demande de remise est facile. Vous pouvez simplement vérifier l'état de vos rabais en cliquant simplement sur ' Suivre ma remise ' et entrez votre adresse e-mail et votre code postal ou votre numéro de réclamation. Pour obtenir une assistance, vous pouvez envoyer par courrier électronique à notre équipe de support de remiseà <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a> Ou appelez notre équipe de support de remise: ${this.selectedProgram.programPhone}.`
        },
        {
          question:
            "J'ai vérifié le statut de ma demande de remise et j'ai indiqué que ma demande de remise n'a pas pu être traitée.Que dois-je faire pour recevoir mon remise?",
          answer: `Si votre demande de remise n'est pas valide en raison d'informations manquantes telles qu'une copie du produit UPC ou de la facture, à condition que vous soyez dans la date limite de réclamation de promotion, veuillez soumettre la facture requise et l'image UPC à notre équipe de support de remise à <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a>. Veuillez vous reporter aux détails de la promotion de la date limite de réclamation.`
        },
        {
          question: "Combien de temps faut-il pour recevoir mon remise?",
          answer:
            "Veuillez allouer jusqu'à 8 semaines à partir de l'envoi de votre soumission pour recevoir votre remise.Il peut arriver plus tôt, mais nous vous demandons d'attendre les 8 semaines complètes pour permettre une période de traitement suffisante."
        },
        {
          question: "Cela fait plus de 8 semaines et je n'ai toujours pas reçu ma remise.Que devrais-je faire?",
          answer: `Première visite <a href='http://www.sony.com/prorebates' target='_blank'>www.sony.com/prorebates</a> et vérifiez l'état de votre réclamation pour vous assurer que votre demande n'a pas été rejetée.Si votre demande a été approuvée et que les huit semaines ont été adoptées, veuillez contacter notre équipe de support de remise par courrier électronique à <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a> ou par téléphone 1.877.745.66686 pour obtenir de l'aide.`
        },
        {
          question: "Pourquoi Sony prend-il si longtemps pour remplir mon remise?",
          answer:
            "Sony utilise une société de réalisation tiers spécialisée dans la transformation des rabais et peut les gérer plus efficacement.Nous devons permettre à la société de traitement de remise suffisamment de temps auditir votre réclamation et pour le traitement de paiement. "
        },
        {
          question:
            "J'ai découvert que ma soumission de remise a été rejetée.Que dois-je faire pour recevoir mon remise?",
          answer: `Si votre remise est rejetée en raison d'informations manquantes telles que l'UPC, le numéro de série ou d'autres exigences de la preuve d'achat, vous pouvez soumettre à nouveau les informations manquantes à l'équipe de support de remise par courrier électronique à <a href='mailto:${this.selectedProgram.programEmail}'>${this.selectedProgram.programEmail}</a>. Vous devriez recevoir un email indiquant que votre remise a été rejetée et la raison de rejet.Nous vous encourageons à soumettre à nouveau votre réclamation avec les informations correctes dans les 30 jours suivant la date de la date.Si votre remise est rejetée en raison des raisons autres que des informations manquantes, veuillez consulter les exigences de remise sur le coupon ou le site Web.Il est possible que les exigences ne soient pas remplies.`
        },
        {
          question:
            "J'ai oublié d'envoyer ma remise et maintenant il a expiré.Si je l'envoie tard, vais-je pouvoir obtenir mon remise?",
          answer:
            "Des promotions telles que des rabais sont soigneusement planifiées pour des cadres de temps très spécifiques.Si la date limite de réclamation est passée pour votre remise, vous ne serez malheureusement pas admissible.S'il vous plaît assurez-vous d'envoyer votre rabais tout de suite à l'avenir."
        },
        {
          question: "Comment les rabais de Sony Paiout 'Cash'?",
          answer:
            "Tous les rabais «en espèces» seront payés aux clients américains sous la forme d'une carte de débit de charge unique et de clients canadiens sous la forme d'un virement bancaire ACH.Pour les clients canadiens, une fois que votre demande approuvée, vous serez contacté directement à partir de l'équipe de support de remise demandant les informations bancaires nécessaires à votre paiement."
        },
        {
          question: "Comment activer ma carte?",
          answer:
            "Appelez le 1-833-848-5768 pour activer votre carte.Vous aurez besoin du code d'accès et du code de sécurité à l'arrière de votre carte.Pendant cet appel, vous recevrez un numéro d'identification personnel (PIN), vous pouvez conserver la broche qui vous est attribuée ou modifier votre code PIN à tout moment en appelant le numéro à l'arrière de votre carte."
        },
        {
          question: "Comment puis-je vérifier les détails de la balance et de la transaction sur ma carte?",
          answer:
            "Les détails de l'équilibre et de la transaction sont toujours disponibles pour vous, gratuitement, en ligne à <a href='http://www.card-support.com' target='_blank'>www.card-support.com</a> ou par téléphone au 1-833-848-5768."
        },
        {
          question: "Comment fonctionne la carte?",
          answer:
            "Une fois votre carte activée, vous pouvez utiliser votre carte pour effectuer des achats chez les marchands en le présentant en personne, en ligne ou par téléphone.Les transactions approuvées seront déduites de votre solde de la carte. Glisser et signer ou utiliser votre code PIN."
        },
        {
          question: "Est-ce que mes fonds expirent-ils jamais?",
          answer:
            "Les fonds disponibles sur votre carte n'expirent pas. Veuillez noter que votre carte a une date de 'Thru valide' à l'avant de la carte.Vous ne pouvez pas utiliser la carte après la date de «Thru valide».Les fonds sur la carte ne seront pas disponibles après cette date.Il est important que vous utilisiez vos fonds avant cette date."
        }
      ];
    }
  },
  mounted() {
    if (this.selectedLocale && this.selectedLocale.languageType.name == "fr") {
      this.faqs = this.faqsFr;
    } else {
      this.faqs = this.faqsEn;
    }
  }
};
</script>
